/* eslint-disable no-console */
import { HttpStatusCode } from '@/data/protocols/http'
import { SendCodeValidationOrigin } from '@/data/use-cases/register-maintenance-security-validation/remote-send-code-validation'
import { WorkflowType } from '@/data/use-cases/register-maintenance-security-validation/status-workflow'
import { ResponseRemoteCheckValidationCode } from '@/data/use-cases/register-maintenance-validation-code/remote-check-validation-code'
import {
  ADDRESS_STORAGE_NAME,
  AddressStorage,
  CHANNEL_STORAGE_NAME,
  ChannelStorage,
} from '@/domain/models/address'
import { MaintenanceValidationCodeMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { CountdownLink } from '@/main/components/countdown-link/countdown-link'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { getObjectInStorage } from '@/main/hooks/useStorage'
import { FACELIFT_WEB_SOURCESYSTEM_ID } from '@/utils/constants'
import { createUrlAddressMaintenance } from '@/utils/create-url-address-maintenance'
import { Alert } from '@naturacosmeticos/natds-react'
import { Spacing, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ControllerProps, FormProvider, useForm } from 'react-hook-form'
import { Container } from '../../register-maintenance-address/components/pages-structures'
import { DialogWithLoader } from '../../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { ValidationCodePageApi } from '../api/make-maintenance-validation-code-page-api'
import { ControlledTextField } from '../components/controlled-text-field'
import {
  ADD_QUANTITY_REQUESTS,
  APPROVED_STATUS,
  DELAY_TO_REQUEST_STATUS_WORKFLOW,
  MAX_LIMIT_RETRY_STATUS,
  REJECTED_STATUS,
  RUNNING_STATUS,
  TIME_TO_RETRY_STATUS,
} from '../utils/constants'

type FormInputs = {
  code: string
}

type CodeInputProps = {
  validChars: RegExp
} & Pick<ControllerProps<'input'>, 'rules'>

export type ValidationCodePageProps = {
  api: ValidationCodePageApi
}

const INVALID_TOKEN_CODE = 'INVALID_TOKEN'
const EXPIRED_TOKEN_CODE = 'EXPIRED_TOKEN'
const INVALID_TOKEN_MESSAGE = 'Invalid Token.'
const INTERNAL_SERVER_ERROR_MESSAGE = 'Internal server error'

export function validateValidationCodeResponse(response: ResponseRemoteCheckValidationCode) {
  if (response.code === INVALID_TOKEN_CODE) {
    throw new Error(INVALID_TOKEN_CODE)
  }

  if (response.code === EXPIRED_TOKEN_CODE) {
    throw new Error(EXPIRED_TOKEN_CODE)
  }

  if (response.message === INVALID_TOKEN_MESSAGE) {
    throw new Error(INVALID_TOKEN_MESSAGE)
  }

  if (response.message === INTERNAL_SERVER_ERROR_MESSAGE) {
    throw new Error(INTERNAL_SERVER_ERROR_MESSAGE)
  }

  if (!response) {
    throw new Error('UNEXPECTED_ERROR')
  }

  return response
}

export const MaintenanceValidationCodePage = ({ api }: ValidationCodePageProps) => {
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true)
  const [inputValidation, setInputValidation] = useState<string>()
  const [infoMessage, setInfoMessage] = useState('')
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)

  const [quantityRetry, setQuantityRetry] = useState(0)

  const { personId, consultantId, countryId, companyId } = useContext(IdentityInfoContext)

  const messages = usePageMessages(Page.MaintenanceValidationCode)
    .messages as MaintenanceValidationCodeMessages

  const codeInput: CodeInputProps = {
    rules: {
      required: true,
      minLength: {
        value: 6,
        message: messages.errorMessageValidationCode,
      },
      pattern: {
        value: new RegExp(/^\d{0,6}$/),
        message: messages.errorMessageValidationCode,
      },
    },
    validChars: new RegExp(/^\d{0,6}$/),
  }

  const formMethods = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      code: '',
    },
  })

  const { watch, control } = formMethods

  const goRejectedPage = () => {
    const urlMaintenanceAddressRejected = createUrlAddressMaintenance({
      url: `mfe-register/maintenanceAddressRejected/${consultantId}`,
      country: countryId,
    })

    return window.location.assign(urlMaintenanceAddressRejected)
  }

  const goApprovedPage = () => {
    const urlMaintenanceAddressApproved = createUrlAddressMaintenance({
      url: `mfe-register/maintenanceAddressApproved/${consultantId}`,
      country: countryId,
    })

    window.location.assign(urlMaintenanceAddressApproved)
  }

  const goGoBackToList = () => window.location.assign('/webfv/list')

  const goBackPreviousPage = () => {
    const urlMaintenanceSecurityValidation = createUrlAddressMaintenance({
      url: `mfe-register/maintenanceSecurityValidation/${personId}`,
      country: countryId,
    })

    window.location.assign(urlMaintenanceSecurityValidation)
  }

  const resendValidationCodeHandler = async (): Promise<void> => {
    const urlMaintenanceSecurityValidation = createUrlAddressMaintenance({
      url: `mfe-register/maintenanceSecurityValidation/${consultantId}`,
      country: countryId,
    })

    window.location.assign(urlMaintenanceSecurityValidation)
  }

  const handleSubmit = async () => {
    try {
      setOpenSubmitLoading(true)

      const addressStorage = getObjectInStorage(ADDRESS_STORAGE_NAME) as AddressStorage
      await api.saveAddress({
        address: addressStorage.address,
        countryId,
        companyId,
        requesterPersonId: personId,
        personId: consultantId,
        functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
        role: ROLES.CONSULTANT,
        businessModel: BUSINESS_MODELS.DIRECT_SALE,
        sourceSystem: FACELIFT_WEB_SOURCESYSTEM_ID,
      })
      setTimeout(() => {
        getStatusWorkflow()
      }, DELAY_TO_REQUEST_STATUS_WORKFLOW)
    } catch (err) {
      if (err && err.statusCode === HttpStatusCode.unauthorized) {
        goGoBackToList()
      } else {
        goRejectedPage()
      }
    }
  }

  const checkCode = async (code: string) => {
    try {
      setOpenSubmitLoading(true)
      const { channel, sequence } = getObjectInStorage(CHANNEL_STORAGE_NAME) as ChannelStorage
      const respoonse = await api.checkValidationCode({
        personId: consultantId,
        countryId,
        companyId,
        verificationCode: code,
        checkType: SendCodeValidationOrigin.CHANGE_ADDRESS,
        channel,
        sequence,
      })

      validateValidationCodeResponse(respoonse)
      setDisableNextButton(false)
      setInfoMessage(messages.successMessageValidationCode)
      setInputValidation('success')
      setOpenSubmitLoading(false)
    } catch {
      setDisableNextButton(true)
      setInfoMessage(messages.errorMessageValidationCode)
      setInputValidation('error')
      setOpenSubmitLoading(false)
    }
  }

  const getStatusWorkflow = async () => {
    if (quantityRetry === MAX_LIMIT_RETRY_STATUS) goRejectedPage()
    try {
      const response = await api.statusWorkflow({
        personId: consultantId,
        businessModel: BUSINESS_MODELS.DIRECT_SALE,
        role: ROLES.CONSULTANT,
        functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
        countryId,
        sourceSystem: '99',
        companyId,
        workflowType: WorkflowType.ADDRESS_MAINTENANCE,
      })
      switch (response.status) {
        case APPROVED_STATUS: {
          goApprovedPage()
          break
        }
        case REJECTED_STATUS: {
          goRejectedPage()
          break
        }
        case RUNNING_STATUS: {
          setTimeout(() => {
            setQuantityRetry((prev) => prev + ADD_QUANTITY_REQUESTS)
            getStatusWorkflow()
          }, TIME_TO_RETRY_STATUS)
          break
        }
        default: {
          goRejectedPage()
          break
        }
      }
    } catch {
      goRejectedPage()
    }
  }

  const handleChange = () => {
    const codeValue = watch('code')
    if (codeValue.length === 6) {
      checkCode(codeValue)
    } else {
      setDisableNextButton(true)
      setInfoMessage(messages.errorMessageValidationCode)
      setInputValidation('error')
    }
  }

  return (
    <Container
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleSubmit}
      disableNextButton={disableNextButton}
      previousButtonLabel={messages.backButtonLabel}
      onPreviousButtonClick={goBackPreviousPage}
    >
      <Helmet>
        <title>{messages.title}</title>
      </Helmet>
      <Typography variant="h5">{messages.title}</Typography>
      <Spacing padding="tiny" />
      <Typography variant="body1">{messages.description}</Typography>
      <Spacing padding="standard" />
      <FormProvider {...formMethods}>
        <ControlledTextField
          customOnChange={handleChange}
          infoMessage={infoMessage}
          state={inputValidation}
          label={messages.inputValidationCodeLabel}
          id="code"
          data-testid="validation-code-input-field-test-id"
          rules={codeInput.rules}
          validChars={codeInput.validChars}
          control={control}
          placeholder={messages.placeholder}
        />
      </FormProvider>
      <Spacing paddingTop="small">
        <CountdownLink
          countdown={300}
          timerMessageSeconds={messages.resendCodeMessageSeconds}
          timerMessageMinutes={messages.resendCodeMessageMinutes}
          linkText={messages.resendLink}
          onClick={resendValidationCodeHandler}
        />
      </Spacing>
      <Spacing padding="small" />
      <Alert title={messages.notReceivedTitle} color="info" type="outlined" data-testid="alert-component">
        <Spacing padding="tiny" />
        <Typography variant="body2">
          <ParseStringToReact stringToParse={messages.contactInformationText} />
        </Typography>
      </Alert>
      <DialogWithLoader isOpen={openSubmitLoading} title={messages.waitingMessage} />
    </Container>
  )
}
