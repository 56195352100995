import { MinimumOrderByCompany } from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import React, { createContext } from 'react'

export type CreditMiGrupoRedDataProps = {
  minimumOrder: number
  available: number
  limit: number
  minimumOrderByCompany?: MinimumOrderByCompany[]
}

export type MiGroupRedPageContextValue = {
  isMiGroupRedPage: boolean
}

type MiGroupProviderProps = {
  isMiGrupoRed: boolean
}

export const MiGroupRedPageContext = createContext<MiGroupRedPageContextValue | undefined>(undefined)

export const MiGroupProvider: React.FC<MiGroupProviderProps> = ({ children, isMiGrupoRed }) => {
  return (
    <MiGroupRedPageContext.Provider value={{ isMiGroupRedPage: isMiGrupoRed }}>
      {children}
    </MiGroupRedPageContext.Provider>
  )
}
