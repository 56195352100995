import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useContactStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: '100%',
  },
  content: {
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0px`,
  },
  textContent: {
    margin: '1rem 0 0.5rem',
  },
  minimumOrderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,

    '& > div:nth-child(2)': {
      marginLeft: '1rem',
      paddingLeft: '1rem',
      borderLeft: '1px solid #E0E0E0',
    },

    '& .companyName': {
      textTransform: 'capitalize',
    },
  },
}))
