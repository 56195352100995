import { MaintenanceValidationCodeMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceValidationCode(translate: TFunction): MaintenanceValidationCodeMessages {
  return {
    title: translate('maintenanceValidationCodePage.title'),
    description: translate('maintenanceValidationCodePage.description'),
    inputValidationCodeLabel: translate('maintenanceValidationCodePage.inputValidationCodeLabel'),
    errorMessageValidationCode: translate('maintenanceValidationCodePage.errorMessageValidationCode'),
    successMessageValidationCode: translate('maintenanceValidationCodePage.successMessageValidationCode'),
    resendLink: translate('maintenanceValidationCodePage.resendLink'),
    resendCodeMessageSeconds: translate('maintenanceValidationCodePage.resendCodeMessageSeconds'),
    resendCodeMessageMinutes: translate('maintenanceValidationCodePage.resendCodeMessageMinutes'),
    notReceivedTitle: translate('maintenanceValidationCodePage.notReceivedTitle'),
    contactInformationText: translate('maintenanceValidationCodePage.contactInformationText'),
    nextButtonLabel: translate('maintenanceValidationCodePage.nextButtonLabel'),
    backButtonLabel: translate('maintenanceValidationCodePage.backButtonLabel'),
    waitingMessage: translate('maintenanceValidationCodePage.waitingMessage'),
    placeholder: translate('maintenanceValidationCodePage.placeholder'),
  }
}
